import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import { FadingLoader } from "../SearchContent.styled";

const { spaces } = xxlTheme;

const headerTypography = {
  fontFamily: "var(--font-family-bold)",
  fontSize: 20,
  lineHeight: 1.2,
  letterSpacing: -0.15,
  laptop: {
    fontSize: 34,
  },
};

export const H1 = styled("h1")`
  display: inline-block;
  ${typographyToCss(headerTypography)};
  margin: 0;
  margin: 0 0 ${spaces.smallRegular};

  ${MQ("tablet")} {
    margin: 0 0 ${spaces.big};
  }
`;

export const StyledHeader = styled(FadingLoader, {
  shouldForwardProp: isPropValid,
})`
  .container:first-of-type {
    ${MQ("tablet")} {
      padding-bottom: 24px 0;
    }
  }
`.withComponent("header");

export const HeaderWrapper = styled("header")`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-block: ${spaces.smallRegular};
  gap: ${spaces.smallRegular};
  ${MQ("tablet")} {
    padding-block: ${spaces.large};
    gap: ${spaces.large};
  }
`;

export const PageHeader = styled("h1")`
  ${typographyToCss(headerTypography)};
  margin-bottom: ${spaces.smallRegular};
`;

export const Query = styled(PageHeader)``;
export const QueryAutoCorrectInfo = styled("span")``;
export const QueryPhrase = styled("i")``;

export const BuyingGuideContainer = styled.div`
  padding-bottom: ${spaces.smallRegular};
  ${MQ("tablet")} {
    padding-bottom: ${spaces.large};
  }
`;

export const BreadcrumbsContainer = styled.div`
  padding: 0;
  margin-top: ${spaces.regular};
  margin-bottom: ${spaces.smallRegular};

  ${MQ("tablet")} {
    margin-block: ${spaces.large};
  }
`;
